import router from './router'
import store from './store/store'
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style
// import getPageTitle from '@/utils/get-page-title'

// NProgress.configure({ showSpinner: false }) // NProgress Configuration

const whiteList = ['/pages/login', '/pages/monitor', '/pages/forgetpassword', '/pages/register', '/pages/error-404', '/freeclinic_print', '/these-auto-login'] // no redirect whitelist

router.beforeEach(async (to, from, next) => {
    // start progress bar
    NProgress.start()
    // set page title
    // document.title = getPageTitle(to.meta.title)

    // determine whether the user has logged in
    const hasToken = localStorage.getItem('jwt') // getToken()

    if (hasToken && hasToken != 'undefined') {
        if (to.path === '/login') {
            // if is logged in, redirect to the home page
            next({ path: '/' })
            NProgress.done()
        } else {
            // await store
            // .dispatch('getInfo')
            // .then((res) => {
            //     if (res.status == 200) {
            //         next()
            //     }
            // })
            // .catch((error) => {
            //     console.log('ERR >> ', error)
            // })
            // .then(() => {
            //     next()
            //     NProgress.done()
            // })
            next()
            NProgress.done()

            // try {
            //   const res = await store.dispatch('getInfo')
            //   if (res.status === 200) {
            //     next()
            //   } else {
            //     // Handle the case where status is not 200, if needed
            //     next('/pages/login')
            //   }
            // } catch (error) {
            //   console.log('ERR >> ', error)
            //   next('/pages/login')
            // } finally {
            //   NProgress.done()
            // }
        }
    } else {
        // has no token
        if (whiteList.includes(to.path)) {
            // in the free login whitelist, go directly
            console.log('whiteList', to.path)
            next()
        } else {
            //為檢查記錄表設定token白名單
            if (to.path.indexOf('freeclinic_print') > -1) {
                next()
            } else {
                next('/pages/login')
                NProgress.done()
            }
        }
    }
})

router.afterEach(() => {
    // finish progress bar
    NProgress.done()
})
